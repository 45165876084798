<!--
 * @Author: 蒋威
 * @Date: 2022-05-07 13:39:02
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-11 08:57:26
 * @Description: 公益活动
-->

<template>
  <div class="homepage-wrap">
    <NavBox @changeMenu="changeMenu" :menuList="menuList" ref="NavBoxRef" />
    <Banner :bannerList="bannerList"></Banner>
    <!-- 全部活动 -->
    <template v-if="['list', 'all'].includes(menuType) && !showDetail">
      <ActiveList
        :id="columnId"
        @toDetail="toDetail"
        ref="activeListRef"
        v-show="!load"
      />
    </template>
    <!-- 不是全部活动 -->
    <template v-if="['', 'info', null].includes(menuType) || showDetail">
      <Activedetails
        v-show="!load"
        :activityData="activityData"
        :signUpList="signUpList"
        :newsList="newsList"
        :activityWebList="activityWebList"
      />
    </template>
  </div>
</template>

<script>
import Activedetails from './components/Activedetails.vue'
import ActiveList from './components/ActiveList.vue'
import { channelById } from '@/api/menu/index.js'
import { websiteAds_list } from '@/api/homePage/index.js'
import { activityInfo, activityApplys } from '@/api/activity/index.js'
import { queryByChannelId } from '@/api/news/index.js'
export default {
  inject: ['reload'],
  name: 'news',
  components: {
    Activedetails,
    ActiveList,
  },
  computed: {},
  data() {
    return {
      menuType: null,
      // 是否在加载数据
      load: true,
      // 活动详情
      activityData: {},
      // 二级菜单数据
      menuList: [
        // { name: '全部活动', id: 7, type: 'all' },
      ],
      // 选中的二级菜单
      activeItem: {},
      // 栏目id
      columnId: null,
      // 轮播图
      bannerList: [],
      // 报名 数据
      signUpList: [],
      // 推荐新闻
      newsList: [],
      // 推荐活动
      activityWebList: [],
      showDetail: false,
    }
  },
  methods: {
    /**
     * @Auth: 蒋威
     * @Date: 2022-05-08 16:04:42
     * @Description: 获取菜单类型
     */
    getmenuType() {
      const id = this.$route.query.id
      if (id) {
        this.menuList.map((item) => {
          if (item.id - 0 == id - 0) {
            this.columnId = item.id
            this.menuType = item.type
            this.activeItem = item
          }
        })
      } else {
        this.columnId = this.menuList[0].id
        this.activeItem = this.menuList[0]
        this.menuType = this.menuList[0].type
      }
    },
    /**
     * @Auth: 蒋威
     * @Date: 2022-05-09 09:34:58
     * @Description: 查看活动详情
     */
    async toDetail(item) {
      // const moduleId = this.$route.query.moduleId
      // let query = {}
      // if (moduleId) {
      //   query = { id: item.id, moduleId: moduleId }
      // } else {
      //   query = { id: item.id }
      // }
      // this.reload()
      // this.$router.push({
      //   name: this.$route.name,
      //   query: query,
      // })
      console.log(item, 'ss')
      const res = await activityInfo(item.id || this.$route.query.id)
      if (res.code === this.$httpCode) {
        this.activityData = res.activity
        this.activityWebList = res.activityWebList
        this.newsList = res.newsList
        this.showDetail = true
      } else {
        this.activityData = {}
        this.activityWebList == []
        this.newsList = []
        this.showDetail = false
      }
      const res1 = await activityApplys(item.id || this.$route.query.id)

      if (res1.code === this.$httpCode) {
        this.signUpList = res1.data
      }
    },
    changeMenu(item) {
      this.menuType = item.type
      const moduleId = this.$route.query.moduleId
      let query = {}
      if (moduleId) {
        query = { id: item.id, moduleId: moduleId }
      } else {
        query = { id: item.id }
      }
      this.reload()
      this.$router.push({
        name: this.$route.name,
        query: query,
      })
    },
    // 获取轮播
    async getBannerList() {
      const res = await websiteAds_list({
        banner_type: 2,
        page: 1,
        limit: 1,
        column_id: this.$route.query.moduleId,
      })
      if (res.code === this.$httpCode) {
        this.bannerList = res.data.data
      }
    },

    async queryByChannelId(param) {
      this.load = true
      switch (this.menuType) {
        case 'all':
          if (!this.showDetail) {
            this.$nextTick(() => {
              this.$refs.activeListRef && this.$refs.activeListRef.init()
            })
          }
          break
        case 'list':
          if (!this.showDetail) {
            this.$nextTick(() => {
              this.$refs.activeListRef && this.$refs.activeListRef.init()
            })
          }
          break
        case 'info':
        case '':
        case null:
          this.toDetail(this.activeItem)
          break
        default:
          break
      }
      this.load = false
    },
    async init() {
      this.getBannerList()
      const res = await channelById({ id: this.$route.query.moduleId })
      if (res.code === this.$httpCode) {
        this.menuList = res.data.websiteChannelVoList || []
        if (this.menuList.length < 1) {
          this.columnId = res.data.WebsiteChannel.id
          this.menuType = res.data.WebsiteChannel.type
          this.activeItem = { id: res.data.WebsiteChannel.id }
        } else {
          this.getmenuType()
        }
        this.queryByChannelId()
      }
    },
  },
  created() {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.homepage-wrap {
  width: 100%;
}
</style>
