<!--
 * @Author: 蒋威
 * @Date: 2022-04-26 09:08:46
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-08-05 08:33:15
 * @Description: 活动详情
-->
<template>
  <div class="response details-container">
    <div class="activity-info">
      <div class="activity-info-left">
        <img v-lazy="activityData.url" alt="" />
      </div>
      <div class="activity-info-right">
        <div class="activity-title">{{ activityData.name || '' }}</div>
        <div class="activity-info-item">
          <span class="activity-lable ellipsis-2">地点</span>
          <span class="activity-text ellipsis-2">
            {{ activityData.address || '' }}
          </span>
        </div>
        <div class="activity-info-item">
          <span class="activity-lable ellipsis-2">费用</span>
          <span class="activity-text ellipsis-2">免费</span>
        </div>
        <div class="activity-info-item">
          <span class="activity-lable ellipsis-2">时间</span>
          <span class="activity-text ellipsis-2">
            {{
              $getDate(activityData.activity_start, 'YMD') +
              ' 至 ' +
              $getDate(activityData.activity_end, 'YMD')
            }}
          </span>
        </div>
        <div class="activity-info-item">
          <span class="activity-lable ellipsis-2">报名</span>
          <span class="activity-text ellipsis-2">
            <!-- {{ signUpList }} -->
            已报名{{ signUpList.length }}人
          </span>
        </div>
        <div class="sign-btn pointer" @click="toSign">我要参加</div>
      </div>
    </div>
    <!-- tab 切换 -->

    <div class="tabMenu">
      <div
        class="tabMenu-item pointer"
        :class="activityType == 0 ? 'active' : ''"
        @click="changeType(0)"
      >
        活动详情
      </div>
      <div
        class="tabMenu-item pointer"
        :class="activityType == 1 ? 'active' : ''"
        @click="changeType(1)"
      >
        已报名 {{ signUpList.length }}
      </div>
    </div>
    <!-- 中间内容 -->
    <div class="activity-container">
      <div
        class="activity-left"
        :class="
          newsList.length < 1 && activityWebList.length < 1 ? 'norightBox' : ''
        "
      >
        <!-- 活动详情 -->
        <template>
          <div class="activity-wrap" ref="activityRef">
            <h2 class="top-title">活动详情</h2>
            <div
              class="activity-content"
              v-html="$getHtml(activityData.content)"
            ></div>
          </div>
          <div class="signup-wrap" ref="signupRef" style="margin-top: 40px">
            <h2 class="top-title">
              <span class="signup-lable">已报名</span>
              <span class="signup-number">
                {{ signUpList.length }}
              </span>
            </h2>
            <div class="signup-content">
              <div
                class="signup-item"
                v-for="(item, index) in signUpList"
                :key="index"
              >
                <img v-lazy="item.head_url" alt="" />
                <div class="signup-name ellipsis-2">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 报名列表 -->
      </div>
      <div
        class="activity-right"
        v-if="newsList.length > 0 || activityWebList.length > 0"
      >
        <!-- 推荐新闻 -->
        <RecommendNews :list="newsList" v-if="newsList.length > 0" />
        <!-- 推荐活动 -->
        <RecommendActivity
          :list="activityWebList"
          v-if="activityWebList.length > 0"
        />
      </div>
    </div>
    <!-- 报名 -->
    <el-dialog
      title="请使用手机微信扫码参加活动"
      top="35vh"
      :visible.sync="signVisible"
      width="30%"
      center
    >
      <div class="qrCode-box">
        <img v-lazy="wx_appletUrl" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { activityApplys } from '@/api/activity/index.js'
import RecommendNews from '@/components/RecommendNews.vue'
import RecommendActivity from '@/components/RecommendActivity.vue'

export default {
  components: { RecommendNews, RecommendActivity },
  props: {
    activityData: {
      type: Object,
      default: {},
    },
    signUpList: {
      type: Array,
      default: () => {
        return []
      },
    },
    newsList: {
      type: Array,
      default: () => {
        return []
      },
    },
    activityWebList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      // 类型 0：详情 1;已报名
      activityType: 0,
      signVisible: false,
      // 活动报名图片
      wx_appletUrl: '/api/templates/wx_applet.png',
    }
  },
  methods: {
    changeType(type) {
      this.activityType = type
      this.$nextTick(() => {
        if (this.activityType == 0) {
          if (this.$refs.activityRef) {
            this.$refs.activityRef.scrollIntoView({ behavior: 'smooth' })
          }
        } else {
          if (this.$refs.signupRef) {
            this.$refs.signupRef.scrollIntoView({ behavior: 'smooth' })
          }
        }
      })
    },
    /**
     * @Auth: 蒋威
     * @Date: 2022-05-10 11:30:02
     * @Description: 参加活动
     */
    toSign() {
      this.signVisible = true
    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.details-container {
  padding: 80px 0;
  .activity-info {
    height: 330px;
    border: 1px solid #eaeaea;
    background-color: #eaeaea;
    display: flex;
    padding: 28px;
    margin-bottom: 40px;
    .activity-info-left {
      width: 540px;
      height: 270px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .activity-info-right {
      flex: 1;
      padding-left: 20px;
      .activity-title {
        height: 29px;
        font-size: 22px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #242121;
        line-height: 26px;
      }

      .activity-info-item {
        height: 40px;
        display: flex;
        align-items: flex-end;
        .activity-lable {
          font-size: 16px;
          width: 106px;
          flex: 0 0 106px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5b5a5a;
          line-height: 20px;
        }
        .activity-text {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #242121;
          line-height: 20px;
        }
      }

      .sign-btn {
        margin-top: 20px;
        width: 260px;
        height: 60px;
        background: #ee722d;
        text-align: center;
        line-height: 60px;
        border-radius: 0px 0px 0px 0px;
        font-size: 20px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        &:hover {
          background: rgba($color: #ee722d, $alpha: 0.9);
        }
      }
    }
  }

  .tabMenu {
    display: flex;
    height: 40px;
    position: relative;
    .tabMenu-item {
      margin-right: 40px;
      height: 100%;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #5b5a5a;
      position: relative;
      &:hover {
        color: #242121;
        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0;
          right: 0px;
          height: 3px;
          background: rgba($color: #e3a63b, $alpha: 0.8);
          z-index: 2;
        }
      }
      &.active {
        color: #242121;
        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0;
          right: 0px;
          height: 3px;
          background: #e3a63b;
          z-index: 2;
        }
      }
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0;
      right: 0px;
      height: 1px;
      background: #cecfce;
    }
  }
  .activity-container {
    // min-height: 800px;
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    .activity-left {
      width: 856px;
      flex: 0 0 856px;
      &.norightBox {
        width: 100%;
        flex: 0 0 100%;
      }
      .activity-wrap {
        .top-title {
          position: relative;
          height: 26px;
          font-size: 20px;
          padding-left: 15px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #242121;
          line-height: 26px;
          &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            height: 26px;
            width: 4px;
            background: #e3a63b;
          }
        }

        .activity-content {
          margin-top: 20px;
          width: 100%;
          padding: 20px 0;
          position: relative;
          word-wrap: break-word;
          ::v-deep p {
            line-height: 32px;
            margin: 20px 0;
          }
          ::v-deep span {
            line-height: 28px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .signup-wrap {
        .top-title {
          position: relative;
          height: 26px;
          font-size: 20px;
          padding-left: 15px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #242121;
          line-height: 26px;
          .signup-lable {
            height: 26px;
            font-size: 20px;
            display: inline-block;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #242121;
            line-height: 26px;
            margin-right: 4px;
          }
          .signup-number {
            display: inline-block;
            height: 26px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5b5a5a;
            line-height: 26px;
          }
          &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            height: 26px;
            width: 4px;
            background: #e3a63b;
          }
        }
        .signup-content {
          margin-top: 20px;
          display: flex;
          flex-wrap: wrap;
          .signup-item {
            width: 70px;
            flex: 0 0 70px;
            text-align: center;
            margin-left: 16px;
            margin-bottom: 12px;
            img {
              width: 48px;
              height: 48px;
              border-radius: 50%;
            }
            .signup-name {
              margin-top: 10px;
              height: 32px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #000000;
              line-height: 16px;
            }
          }
        }
      }
    }

    .activity-right {
      width: 290px;
      flex: 0 0 290px;
    }
  }
}
.qrCode-box {
  text-align: center;
  img {
    width: 200px;
    height: 200px;
  }
}
</style>