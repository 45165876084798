<!--
 * @Author: 蒋威
 * @Date: 2022-04-26 09:08:46
 * @LastEditors: chenpeng
 * @LastEditTime: 2022-05-19 10:45:25
 * @Description: 活动列表
-->
<template>
  <div class="response publicWelfare-container">
    <div class="publicWelfare-wrap">
      <template v-for="(item, index) in activityList">
        <div
          class="publicWelfare-item pointer"
          :key="index"
          :class="setStateClass(item.activity_status)"
          @click="toDetail(item)"
        >
          <div class="img-wrap">
            <img v-lazy="item.url" alt="" />
            <div
              class="activity-state-lable"
              :class="setStateClass(item.activity_status)"
            >
              活动{{ item.activity_status_text }}
            </div>
          </div>
          <!-- 日期 -->
          <div class="act-center">
            <p>{{ $getDate(item.activity_start, 'M') }}月</p>
            <h5>{{ $getDate(item.activity_start, 'D') }}日</h5>
          </div>
          <!-- 标题 简介 -->
          <div class="bottom-wrap">
            <p class="ellipsis-2">{{ item.name }}</p>
            <h3 class="ellipsis-2">{{ item.address }}</h3>
          </div>
        </div>
      </template>
    </div>

    <Page
      :page-size="form.limit"
      :total="total"
      :current-page="form.page"
      @currentChange="currentChange"
    ></Page>
  </div>
</template>

<script>
import { queryByChannelId } from '@/api/news/index.js'
export default {
  name: 'ActiveList',
  props: {
    id: {
      type: Array | String,
    },
  },
  data() {
    return {
      form: {
        limit: 6,
        page: 1,
      },
      total: 0,
      activityList: [
        // {
        //   state: 0,
        //   id: 8,
        //   img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        //   stateName: '活动已进行中',
        //   title: '童行课堂项目问卷调查 教师版',
        //   addr: '童行课堂项目问卷调查',
        //   tiem: '09月26日',
        //   date: '2022-09-26 16:00:21',
        // },
      ],
    }
  },
  methods: {
    // 设置活动类名
    setStateClass(state) {
      switch (state) {
        // 未开始
        case 1:
          return 'notStarted'
          break
        // 进行中
        case 2:
          return 'conduct'
          break
        // 结束
        case 3:
          return 'end'
          break

        default:
          return ''
          break
      }
    },
    async queryByChannelId(param) {
      let res = await queryByChannelId({ id: this.id, ...this.form })

      if (res.code === this.$httpCode) {
        this.activityList = res.data.activityPage.data
        this.total = res.data.activityPage.total
        this.form.page = res.data.activityPage.current_page
      } else {
        this.activityList = []
        this.total = 0
      }
      console.log(res, this.activityList, 'ssdtgvss')
    },
    currentChange(val) {
      this.form.page = val
      this.queryByChannelId()
    },
    // 重置数据
    reset() {
      this.activityList = []
      this.total = 0
      this.form = {
        limit: 6,
        page: 1,
      }
    },
    // 初始化
    init() {
      this.reset()
      this.queryByChannelId()
    },
    /**
     * @Auth: 蒋威
     * @Date: 2022-05-09 09:31:58
     * @Description: 去活动详情
     */
    toDetail(item) {
      this.$emit('toDetail', item)
    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.publicWelfare-container {
  padding: 70px 0;
  .publicWelfare-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .publicWelfare-item {
      width: 387px;
      flex: 0 0 387px;
      height: 424px;
      margin-right: 18px;
      border-radius: 10px 10px 10px 10px;
      position: relative;
      margin-bottom: 18px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &.conduct {
        background: #f8b92b;
      }
      &.notStarted {
        background: #f8682b;
      }
      &.end {
        background: #f8b92b;
      }
      .img-wrap {
        width: 387px;
        height: 251px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .activity-state-lable {
          position: absolute;
          right: 0;
          top: 0;
          width: 150px;
          text-align: center;
          background: #f8b92b;
          height: 34px;
          padding: 4px 0;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: rgba($color: #fff, $alpha: 1);
          line-height: 26px;
          &.conduct {
            background: #f8b92b;
          }
          &.notStarted {
            background: #f8682b;
          }
          &.end {
            background: #e6e6e6;
            color: #414141;
          }
        }
      }
      .act-center {
        position: absolute;
        width: 86px;
        height: 94px;
        background: #ffffff;
        top: 220px;
        left: 30px;
        padding: 14px 0;
        p {
          height: 26px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #e3a63b;
          line-height: 26px;
          text-align: center;
        }
        h5 {
          height: 26px;
          margin-top: 6px;
          font-size: 18px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #e3a63b;
          line-height: 26px;
          text-align: center;
        }
      }
      .bottom-wrap {
        height: 173px;
        width: 100%;
        padding: 80px 10px 0 30px;
        p {
          height: 40px;
          font-size: 16px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 20px;
        }
        h3 {
          height: 32px;
          margin-top: 10px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 16px;
        }
      }
      &:hover {
        background: #e3a63b;
      }
    }
  }
}
</style>